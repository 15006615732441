import { makeStyles } from '@admitkard/ccl/style';
import React from 'react';
import { AbcSVG, BookSVG, CalculatorSVG, ScholarshipSVG , PassportSVG , AccommodationSVG } from '../../SvgCollection';
import { HeaderLabel } from '../CommonComponents';
import FeatureCard from './FeatureCard';
import { useRouter } from 'next/router';
import { PRE_LOGIN_URLS } from 'constants/links';
import LoanModal from 'components/Home/HomeV2/Components/Sections/FeaturesSection/LoanModal';
import ExpertModal from 'components/Home/HomeV2/Components/Sections/FeaturesSection/ExpertModal';
import VisaModal from 'components/Home/HomeV2/Components/Sections/FeaturesSection/VisaModal';


const useStyles = makeStyles(
  () => ({
    outerContainer: {
      width: '100%',
    },
    featureContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(361px, 1fr))',
      gap: 'clamp(.25rem, 1vw, 0.5rem)',
      justifyContent: 'center',
    },
    bookSVG: {
      height: '9.25rem',
      width: '6.75rem',
      '@media screen and (min-width: 992px)': {
        width: '8rem',
        height: '12.25rem',
        right: '0.75rem',
        position: 'relative',
      },
    },
    abcSVG: {
      position: 'relative',
      right: '0.35rem',
      height: '9.25rem',
      width: '6.75rem',
      '@media screen and (min-width: 992px)': {
        width: '9.5rem',
        height: '12rem',
      },
    },
    calculatorSVG: {
      height: '8.15rem',
      width: '6.75rem',
      '@media screen and (min-width: 992px)': {
        width: '8.75rem',
        height: '12.25rem',
      },
    },
    scholarshipSVG: {
      height: '7.75rem',
      width: '6.75rem',
      '@media screen and (min-width: 992px)': {
        width: '12rem',
        height: '12.75rem',
      },
    },
    passportSVG: {
      position: 'relative',
      right: '0.35rem',
      height: '9.25rem',
      width: '6.75rem',
      '@media screen and (min-width: 992px)': {
        width: '9.5rem',
        height: '12rem',
      },
    },
    accommodationSVG: {
      position : 'relative',
      top : '1.25rem',
      height: '7.75rem',
      width: '6.75rem',
      '@media screen and (min-width: 992px)': {
        width: '12rem',
        height: '12.75rem',
      },
    },
  }),
  { name: 'FeatureSection' }
);

const FeatureSection: React.FunctionComponent = () => {
  const classes = useStyles();
  const [isLoanModal, setIsLoanModal] = React.useState(false);
  const [isExpertModal, setIsExpertModal] = React.useState(false);
  const [isVisaModal, setIsVisaModal] = React.useState(false);
  const nextRouter = useRouter();

  function handleScholarshipClick() {
    nextRouter.push(PRE_LOGIN_URLS.PAFScholarship);
  }

  function handleIeltsClick() {
    nextRouter.push('/ielts');
  }


  return (
    <div id="features" className={classes.outerContainer}>
      <HeaderLabel label="ensuring you get the" highlightText="best in" />
      <div id='feature-card' className={classes.featureContainer}>
        <FeatureCard
          backgroundColor="hsla(29, 100%, 94%, 1)"
          title="Shortlist Universities"
          content="Find your dream university with our advanced Course Finder"
          actionLabel="Find my dream university"
          modalId='/paf'
          featureGraphic={<div className={classes.bookSVG}>{BookSVG}</div>}
        />
        <FeatureCard
          backgroundColor="hsla(163, 62%, 89%, 1)"
          title="Prepare for IELTS"
          content="Take the personalized assistance of our in-house IELTS experts"
          actionLabel="Know more"
          featureGraphic={<div className={classes.abcSVG}> {AbcSVG} </div>}
          modalId="#ieltsModal"
          onClick={handleIeltsClick}
        />
        <FeatureCard
          backgroundColor="hsla(270, 100%, 97%, 1)"
          title="Get Education Loan"
          content="Finance your study abroad dreams with AK Finance"
          actionLabel="Talk to loan expert"
          featureGraphic={<div className={classes.calculatorSVG}>{CalculatorSVG}</div>}
          modalId="#loanModal"
          isModal={isLoanModal}
          setIsModal={setIsLoanModal}
        />
        <FeatureCard
          backgroundColor="hsla(29, 100%, 94%, 1)"
          title="Apply for Scholarship"
          content="Find the best scholarships available for your profile "
          actionLabel="Find scholarship"
          featureGraphic={<div className={classes.scholarshipSVG}>{ScholarshipSVG}</div>}
          onClick={handleScholarshipClick}
        />
        <FeatureCard
          backgroundColor="hsla(163, 62%, 89%, 1)"
          title="Apply for Visa"
          content="Get world class visa assistance from our experts"
          actionLabel="Apply for visa"
          featureGraphic={<div className={classes.passportSVG}> {PassportSVG} </div>}
          modalId="#visaModal"
          isModal={isVisaModal}
          setIsModal={setIsVisaModal}
        />
        <FeatureCard
          backgroundColor="hsla(270, 100%, 97%, 1)"
          title="Student Accommodation"
          content="Book your accommodation near top universities across the globe"
          actionLabel="Find accommodation"
          modalId='accommodations/paf'
          featureGraphic={<div className={classes.accommodationSVG}>{AccommodationSVG}</div>}
        />
        <LoanModal isModal={isLoanModal} setIsModal={setIsLoanModal} />
        <ExpertModal isModal={isExpertModal} setIsModal={setIsExpertModal} />
        <VisaModal isModal={isVisaModal} setIsModal={setIsVisaModal} />
      </div>
    </div>
  );
};

export default FeatureSection;
