import { makeStyles } from '@admitkard/ccl';
export const useStyles = makeStyles(
  () => ({
    root: {
      height: 'calc(calc(100 * var(--vh)) - 80px)',
      display: 'grid',
      justifyContent: 'center',
      alignItems: 'center',
    },
    modal: {
      display: 'block',
      marginTop: '3rem',
      outline: 'none',
      border: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      backdropFilter: 'blur(8px)',
      '-webkit-backdrop-filter': 'blur(8px)',
      '@media screen and (min-width: 991px)': {
        display: 'grid',
        alignContent: 'center',
        inset: '1.75rem',
        marginTop: '0',
      },
      '& div':{
        outline: 'none',
        border: 'none',
      },
      '& :nth-child(2)':{
        outline: 'none',
        border: 'none',
      },
    },
    backDrop: {
      '-webkit-backdrop-filter': 'blur(8px)',
      backdropFilter: 'blur(8px)',
      boxShadow: 'inset 0 0 0 200px rgba(255,255,255,0.08)',
    },
  }),
  { name: 'Modal' }
);
