import { makeStyles } from '@admitkard/ccl/style';
import React from 'react';
import { useRouter } from 'next/router';

export type FeatureCardProps = {
  title: string;
  content: string;
  actionLabel: string;
  featureGraphic: JSX.Element;
  backgroundColor: string;
  modalId?: string;
  isModal?: boolean;
  setIsModal?: (value: boolean) => void;
  onClick?: () => void;
};

const useStyles = (backgroundColor: string) =>
  makeStyles(
    (theme) => ({
      root: {
        background: backgroundColor,
        padding: '1.125rem 0 1rem 1rem',
        borderRadius: '1rem',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        margin: '0 1rem',
        overflow: 'hidden',
        '@media screen and (min-width: 992px)': {
          margin: '0',
          paddingTop: '2.5rem',
          paddingLeft: '2.625rem',
          borderRadius: '1.75rem',
        },
      },
      cardTitle: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: '1.5rem',
        color: theme.colorPalette.indigo.main,
        '@media screen and (min-width: 992px)': {
          fontSize: '1.5rem',
        },
      },
      cardText: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '0.75rem',
        lineHeight: '150.5%',
        width: '13.9375rem',
        height: '2.25rem',
        color: theme.colorPalette.indigo.main,
        '@media screen and (min-width: 992px)': {
          fontSize: '1rem',
          width: '18.125rem',
        },
      },
      cardButtonContainer: {
        zIndex: 1,
        height: '3rem',
        width: 'max-content',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '1rem 1rem 1rem 1.25rem',
        gap: '0.75rem',
        background: '#FFFFFF',
        borderRadius: '1.875rem',
        boxSizing: 'border-box',
        fontSize: '0.875rem',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        color: theme.colorPalette.indigo.main,
        border: 'none',
        textDecoration: 'none',
        cursor: 'pointer',
        '&:hover': {
          transform: 'translateY(-0.2rem)',
          boxShadow: '0px 3px 0px rgb(0 0 0 / 25%)',
        },
        '& span': {
          overflow: 'hidden',
          maxWidth: '15ch',
          whiteSpace: 'nowrap',
          '@media screen and (min-width: 992px)': {
            overflow: 'unset',
            maxWidth: 'unset',
            whiteSpace: 'unset',
          },
        },
        '@media screen and (min-width: 992px)': {
          fontSize: '1.1rem',
          height: '3.5rem',
          marginTop: '3rem',
          marginBottom: '3rem',
        },
      },
      cardButtonIconContainer: {
        height: '1.25rem',
        aspectRatio: '1',
        '@media screen and (min-width: 992px)': {
          height: '1.5rem',
        },
        '& svg': {
          height: 'inherit',
          width: 'inherit',
        },
      },
      cardImage: {
        position: 'absolute',
        right: '0',
        bottom: '-1rem',
      },
    }),
    { name: 'FeatureCard' }
  );

export const FeatureCard: React.FunctionComponent<FeatureCardProps> = (props) => {
  const classes = useStyles(props.backgroundColor)();
  const router = useRouter();
  const handleOnClick = () => {
    if(props.onClick) {
      props.onClick();
    } else {
      if (props.setIsModal) {
        props.setIsModal(true);
      } else if (props.modalId) {
        router.push(props.modalId);
      }
    }
  };
  return (
    <div className={classes.root}>
      <div className={classes.cardTitle}>{props.title}</div>
      <div style={{ height: '1rem' }} />
      <div className={classes.cardText}>{props.content}</div>
      <div style={{ height: '1.5rem' }} />
      <button type="button" id="cta-feature-card" onClick={handleOnClick} className={classes.cardButtonContainer}>
        {props.actionLabel}
        <span className={classes.cardButtonIconContainer}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="12" fill="#EDDFFF" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.8425 11.8281C12.9207 11.75 12.9207 11.6234 12.8425 11.5453L9.69108 8.39379C9.49582 8.19853 9.49582 7.88195 9.69108 7.68668C9.88634 7.49142 10.2029 7.49142 10.3982 7.68668L14.0446 11.3331C14.2399 11.5284 14.2399 11.845 14.0446 12.0402L10.3982 15.6867C10.2029 15.8819 9.88634 15.8819 9.69108 15.6867C9.49582 15.4914 9.49581 15.1748 9.69108 14.9796L12.8425 11.8281Z"
              fill="#483287"
            />
          </svg>
        </span>
      </button>
      <div className={classes.cardImage}>{props.featureGraphic}</div>
    </div>
  );
};

export default FeatureCard;
