import { Modal } from '@admitkard/ccl';
import { ModalInner } from '@admitkard/common/RegisterModal';
import { useStyles } from 'components/LoginRegister/ModalStyle.css';
import React, { FunctionComponent } from 'react';

const LoanModal: FunctionComponent<any> = (props) => {
  const { isModal, setIsModal } = props;
  const classes = useStyles();
  const onClose = () => {
    setIsModal(false);
  };

  return (
    <React.Fragment>
      <Modal className={classes.modal} open={isModal} onClose={onClose}>
        <div>
          <ModalInner heading='Talk to Loan Expert' bannerBackground="https://assets.admitkard.com/images/loan_expert.webp" onClose={onClose} />
        </div>
      </Modal>
    </React.Fragment>
  );
};
export default LoanModal;
